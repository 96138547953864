import Text from '@components/atoms/Text';
import Title from '@components/atoms/Title';
import Button from '@components/Button';
import { Logo } from '@components/website/SiteHeader/NavComponents';

import styles from './styles.module.scss';

export const ErrorPage = () => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.info}>
        <Title as="h1" variant="h1">
          Oops something went wrong
        </Title>
        <Text as="p" variant="b1" color="navy">
          Sorry page is missing or you used an incorrect link.
        </Text>
        <div>
          <Button
            className={styles.button}
            variant="bigMain"
            href="/"
            aria-label="Go to homepage"
          >
            Return to Pio.com
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default ErrorPage;
